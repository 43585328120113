import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import {
  Collections,
  type FairyOutfitsRecord,
  type FairyRecord,
} from "~/types/pocketbase-types";

export function useFairyOutfit(fairyId: MaybeRefOrGetter<string>) {
  const fairyIdRef = toRef(fairyId);
  const queryClient = useQueryClient();

  const { pocketbase } = storeToRefs(usePocketBase());

  const { data: fairy } = useQuery({
    queryKey: ["fairy", fairyIdRef],
    queryFn: () =>
      pocketbase.value
        .collection(Collections.Fairies)
        .getOne<FairyRecord>(fairyIdRef.value),
  });

  const fairyLevel = computed(() => fairy.value?.level);

  const outfitsQuery = useQuery({
    queryKey: ["fairy_outfits", fairy],
    select: (outfits) => {
      return outfits.map((outfit) => ({
        ...outfit,
        image: pocketbase.value.getFileUrl(outfit, outfit.image),
      }));
    },
    queryFn: () =>
      pocketbase.value
        .collection(`fairy_outfits`)
        .getFullList<FairyOutfitsRecord>({
          filter: `fairy.id="${fairy.value?.id}" && fairy_level="${fairy.value?.level}"`,
        }),
  });

  const currentOutfit = computed(() =>
    outfitsQuery.data.value?.find((outfit) => outfit.is_current),
  );

  const { mutate: setCurrentOutfit } = useMutation({
    mutationFn: async (newCurrentOutfitId: string) => {
      if (!outfitsQuery.data.value) {
        return;
      }

      if (!outfitsQuery.data.value) {
        return;
      }

      if (newCurrentOutfitId === currentOutfit.value?.id) {
        return;
      }

      if (
        !outfitsQuery.data.value.some(
          (outfit) => outfit.id === newCurrentOutfitId,
        )
      ) {
        return;
      }

      if (currentOutfit.value) {
        await pocketbase.value
          .collection(`fairy_outfits`)
          .update<FairyOutfitsRecord>(currentOutfit.value.id, {
            is_current: false,
          });
      }

      await pocketbase.value
        .collection(`fairy_outfits`)
        .update<FairyOutfitsRecord>(newCurrentOutfitId, {
          is_current: true,
        });

      console.log(fairy);
      queryClient.invalidateQueries({
        queryKey: ["fairy_outfits", fairy],
      });
    },
  });

  return {
    ...outfitsQuery,
    currentOutfit,
    setCurrentOutfit,
    fairyLevel,
  };
}
